import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';
import HornImg from "../images/luidsprekerhartjes.png"
import WinkelImg from "../images/winkeltje.png"
import VrachtawagentjeImg from "../images/vrachtwagentje.png"
import WinkelmandjeImg from "../images/winkelmandje.png"
import SpaarvarkenImg from "../images/spaarvarkentje.png"
import ContactImg from "../images/envelopje.png"
import ProductImg from "../images/extra/appel.png"
import AdministratieImg from "../images/extra/administratie.png"
import PijlImg from "../images/extra/pijl.png"
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from "gatsby";
import video from "../video/steunverkoop.mp4"

const Index = () => (
  <Layout>
    <section className="pt-10 md:pt-20">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Steunverkoop
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            De fondsenverwerving voor jouw organisatie sterk veréénvoudigd en kosteloos.
          </p>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Uw 'jaarlijkse' verkoop via een webshop zonder enige extra kosten ? We begeleiden je van A tot Z. 
          </p>
          <p className="mt-8 md:mt-12">
          	<AnchorLink className="px-4" href="#who">
            	<Button size="lg">Meer info</Button>
            </AnchorLink>
          </p>
          <p className="mt-4 text-gray-600">Spek uw kas en start vandaag met je verkoop met je eigen webshop.</p>
        </div>
        <div className="lg:w-1/2">
              <img src={HornImg} alt="Listen" />
        </div>
      </div>
    </section>
    <section id="who" className="pt-40 sm:pt-0 md:pt-0 lg:pb-18 lg:pt-18">
    	<div className="container mx-auto text-center">
        	<h2 className="text-3xl lg:text-5xl font-semibold">Voor wie ?</h2>
        	    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <p className="mt-8 text-xl font-light leading-relaxed">
            Steunverkoop is een platform om fondsenwerving te vergemakkelijken voor scholen, sportclubs, verenigingen, en andere uitzonderlijke goede doelen. 
          </p>
          <p className="mt-8 text-xl font-light leading-relaxed">
            De organisatoren van fondsverwervende initatieven zijn bij ons aan het goede adres. Wij bieden u de juiste tools aan zonder enige investering. 
          </p>
        </div>
      }
      secondarySlot={<img src={SpaarvarkenImg} alt="Listen" />}
    />
        	
        </div>	
        
        
    </section>
    <section id="features" className="pt-40 sm:pt-0 md:pt-0 lg:pb-18 lg:pt-18">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Service</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Webshop</p>
              <p className="mt-4">
                Wij bieden jouw organisatie kosteloos een eigen webshop aan, onder eigen logo, waarop je doelpubliek kan kopen en de werking kan steunen. Dit met een duidelijke winstmarge voor ogen.
              </p>
              {<img src={PijlImg} alt="Listen" />}
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Producten</p>
              <p className="mt-4">
                U hoeft zelf geen producten aan te kopen. U kan producten kiezen uit onze catalogus. Er is een ruime keuze aan kwalitatieve producten waaronder ook duurzame, biologische en lokale producten.
              </p>
              {<img src={ProductImg} alt="Listen" />}
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Administratie</p>
              <p className="mt-4">
                U kan de verkoop realtime meevolgen online. Je hoeft enkel nog jouw doelpubliek naar de site te verwijzen. Een vlotte afhandeling is verzekerd. 
              </p>
              {<img src={AdministratieImg} alt="Listen" />}
            </Card>
          </div>
        </div>
      </div>
    </section>
    <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Stap 1 : Aanbod samenstellen</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            We gaan samen na wat je wil aanbieden op de webshop van je organisatie, tegen welke prijs en je bepaalt zo je marge. Wij werken ook samen met lokale producten en producenten. Zo steun je ook de lokale economie, nietwaar ? 
          </p>
        </div>
      }
      secondarySlot={<img src={WinkelImg} alt="Listen" />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Stap 2 : Verkopen op je webshop
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Wij plaatsten de producten op je webshop en jullie doelpubliek kan beginnen kopen. Jullie zorgen verder voor de promotie en dat er op jullie webshop wordt verkocht. Jullie volgen de verkoop online, realtime. En jullie cashen de winst.
          </p>
        </div>
      }
      secondarySlot={<img src={WinkelmandjeImg} alt="Listen" />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Stap 3 : Bezorgen
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Wij bezorgen jou netjes de gekochte goederen. We duiden daarbij ook duidelijk aan aan wie ze kunnen worden verdeeld. Per klas, per team, leefgroep,whatever. Zoals jullie het willen. Zo simpel is het.    
          </p>
        </div>
      }
      secondarySlot={<img src={VrachtawagentjeImg} alt="Listen" />}
    />
    <section id="stats" className="pt-40 sm:pt-0 md:pt-0 lg:pt-32">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Jullie Voordeel</h2>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+20%" secondaryText="Meer verkopen" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="-80%" secondaryText="Tijdsbesteding" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="0%" secondaryText="Papier" />
          </div>
        </div>
      </div>
    </section>
    
    <section id="demo" className="pt-40 sm:pt-0 md:pt-0 lg:pt-32">
    <div className="container mx-auto text-center">
    	<h2 className="text-3xl lg:text-5xl font-semibold">Demonstratie</h2>
    	<video controls>
			<source src={video} type="video/mp4" />
		</video>
	</div>
	</section>
    
    <section id="testimonials" className="pt-40 sm:pt-0 md:pt-0 lg:pt-40">
      <div className="container mx-auto">
        <h2 className="text-3xl lg:text-5xl font-semibold text-center">Wat onze klanten vinden :</h2>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>
    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
		
			<div>
				<h3 className="text-5xl font-semibold">Klaar voor jullie steunverkoop ?</h3>
				<p className="mt-8 text-xl font-light">
					Het is easy peasy 😊
				</p>
				<p className="mt-8 text-xl font-light">
					Klik op de knop hieronder en we zijn begonnen 🎉
				</p>
				<p className="mt-8">
					<Link to="/contact" className="px-4">
						<Button size="xl">Contacteer ons.</Button>
					</Link>
					
				</p>
				
		
		</div>	
    
      
    </section>
  </Layout>
);

export default Index;
