export default [
  {
    title: 'Crea16',
    content:
      'Een hele veréénvoudiging ten opzichte van vroeger en een correcte afhandeling. Volgend jaar doen we terug een steunverkoop.',
    customerName: '',
    customerTitle: 'Directeur van de school',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: 'Gembo, basketbalvereniging met jongerenwerking',
    content:
      'Dé nieuwe vorm van fondsenverwerving zonder kosten voor de organisatie. een win-win',
    customerName: '',
    customerTitle: 'Voorzitter van de ouderraad',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: 'Less is more',
    content:
      'Geen onnodige administratie, minder financiële opvolging en controle, geen handmatig optellen van bestellingen, geen fouten, meer opbrengst',
    customerName: '',
    customerTitle: 'Administratief medewerkster',
    customerImage: 'https://placeimg.com/150/150/people'
  }
];
