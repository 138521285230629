import React, { useState }  from 'react';
import { Link, navigate, graphql, useStaticQuery } from 'gatsby';
import LogoIconNew from '../../images/luidsprekertje-icon.png';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '../Button';

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
    const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
      <header className="sticky top-0 bg-white shadow">
    <div className="container mx-auto flex flex-wrap items-center justify-between max-w-7xl mx-auto md:pt-8 md:pb-8 md:pr-5 md:pl-5 text-xl">
        <Link to="/">
          <h1 className="flex items-center sidebar-link no-underline">
            <img src={LogoIconNew} alt="Listen" />
            <span className="text-xl font-bold tracking-tight">
              {site.siteMetadata.title}
            </span>
          </h1>
        </Link>

        <button
          className="items-center block px-3 py-2 text-black border border-black rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:items-center w-full md:w-auto`}
        >
        
      	   {[
            {
              route: `#who`,
              title: `Voor wie`,
            },
            {
              route: `#features`,
              title: `Services`,
            },
            {
              route: `#stats`,
              title: `Waarom`,
            },
            {
              route: `#testimonials`,
              title: `Realisaties`,
            },
          ].map((link) => (
            <AnchorLink
              className="block mt-4 md:inline-block md:mt-0 md:ml-6 hover:underline"
              href={link.route}
              onClick={() => toggleExpansion(!isExpanded)}
            >
              {link.title}
            </AnchorLink>
          ))
           
          }
          <Link to="/contact" className="block mt-4 font-playfair md:inline-block md:mt-0 md:ml-6 hover:underline">
		  	Contact
		  </Link>
        </nav>
      </div>
    </header>
  );
}

export default Header;